import {Md5} from "ts-md5";
import {isNil, isObject, isString} from "lodash";

import {RequestOptionsArgs} from "./type";

import {urlEncodeParams, resolveUrl} from "./utils";
import {getCurrentToken} from "./token";
import {getStorage} from "../funcs/storage";

export class HttpRequest {
    readonly responseType: "arraybuffer" | "blob" | "json" | "text" = "json";

    readonly options?: RequestOptionsArgs;

    constructor(public url: string, options: RequestOptionsArgs = {}) {
        this.responseType = options.responseType || this.responseType;
        this.options = options;
    }

    get request() {
        const {headers: rawHeaders, body: rawBody, params} = this.options!,
            url = resolveUrl(this.url, `${this.getUrlEncodeParams(params)}`),
            headers = this.getHeaders(rawHeaders),
            body = rawBody === void 0 ? void 0 : rawBody instanceof FormData ? (delete headers["Content-Type"], rawBody) : JSON.stringify(rawBody);

        this.attachXRequestIdToHeader(headers, url, isString(body) ? new TextEncoder().encode(body).length : null);

        return new Request(url, {
            ...this.options,
            body,
            headers,
        } as any);
    }

    protected getUrlEncodeParams(params: any) {
        return isNil(params) ? "" : isObject(params) ? urlEncodeParams(params) : params;
    }

    protected attachXRequestIdToHeader(headers: any, url: string, contentLength: number | null = null) {
        if (url && headers.Authorization) {
            headers["X-Request-Id"] = Md5.hashStr(`${new URL(url, location.href)}:${headers.Authorization}${contentLength ? `:${contentLength}` : ""}`);
        }

        return headers;
    }

    protected getHeaders(headers: any) {
        headers = this.attachAuthorization({
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            ...headers,
        });

        this.attachUnitId(headers);
        this.attachParkId(headers);

        return headers;
    }

    protected attachAuthorization(headers: any) {
        const {allowAnonymous} = this.options!;

        if (!allowAnonymous && !headers.Authorization) {
            const authorization = getCurrentToken();

            if (authorization) {
                headers.Authorization = authorization;
            }
        }

        return headers;
    }

    protected attachUnitId(headers: any) {
        const {unitId = getStorage("unitId")} = this.options!;

        delete headers.unitId;

        unitId && (headers.unitId = unitId);
    }

    protected attachParkId(headers: any) {
        const {parkId = getStorage("parkId")} = this.options!;

        delete headers.parkId;

        parkId && (headers.parkId = parkId);
    }

    clone(): HttpRequest {
        return new HttpRequest(this.url, this.options);
    }
}
